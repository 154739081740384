import ic_instagram from './icons/ic_instagram.svg';
import ic_ymusic from './icons/ic_ymusic.svg';
import ic_youtube from './icons/ic_youtube.svg';
import ic_itunes from './icons/ic_itunes.svg';
import ic_spotify from './icons/ic_spotify.svg';
import ic_deezer from './icons/ic_deezer.svg';
import ic_soundcloud from './icons/ic_soundcloud.svg';
import ic_bandcamp from './icons/ic_bandcamp.svg';
import coverZone from './images/zone-cover.jpg'
import cover12347 from './images/012347-cover.jpg'
import './App.css';

function App() {
  return (
    <div className="container">
      <div className="content">
        <div className="header">
          <p>Stechanka is a dark ambient project by Stanislav Shephard.</p>
          <p>Formed in 2018 as a way to explore and re-create a certain atmosphere – a post apocalyptic stillness among Chernobyl Exclusion Zone.</p>
          <p>Music that is both apocalyptic and soothing, a kind of meditative ambience.</p>
        </div>
        <div className="albums">
          <div className="album">
            <img src={coverZone} className="cover-image" alt="Stechanka - Zone" />
            <p className="album-title">Stechanka - Zone (2021)</p>
            <div className="download-links">
              <a href='https://s3.eu-central-1.amazonaws.com/stechanka.com/downloads/Stechanka_zone_mp3.zip' className="download-link">Download (mp3)</a>
              <a href='https://s3.eu-central-1.amazonaws.com/stechanka.com/downloads/Stechanka_zone_flac.zip' className="download-link">Download (flac)</a>
            </div>
          </div>
          <div className="album">
            <img src={cover12347} className="cover-image" alt="Stechanka - 12347" />
            <p className="album-title">Stechanka - 01:23:47 (2019)</p>
            <div className="download-links">
              <a href='https://s3.eu-central-1.amazonaws.com/stechanka.com/downloads/Stechanka_012347_mp3.zip' className="download-link">Download (mp3)</a>
              <a href='https://s3.eu-central-1.amazonaws.com/stechanka.com/downloads/Stechanka_012347_flac.zip' className="download-link">Download (flac)</a>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="media-links">
          <a href='https://www.instagram.com/stechanka' target="_blank" rel="noreferrer" className="media-link">
            <img src={ic_instagram} className="media-icon" alt="instagram" />
            <span>instagram</span>
          </a>

          <a href='https://music.youtube.com/channel/UCGOvL4f4tvlcblmDLHwHLRA' target="_blank" rel="noreferrer"  className="media-link">
            <img src={ic_ymusic} className="media-icon" alt="youtube music" />
            <span>youtube music</span>
          </a>

          <a href='https://www.youtube.com/channel/UCAoa317p4E-CkA5myZl7yFw/featured' target="_blank" rel="noreferrer"  className="media-link">
            <img src={ic_youtube} className="media-icon" alt="youtube" />
            <span>youtube</span>
          </a>

          <a href='https://music.apple.com/ru/artist/stechanka/1603296913' target="_blank" rel="noreferrer"  className="media-link">
            <img src={ic_itunes} className="media-icon" alt="apple music" />
            <span>apple music</span>
          </a>

          <a href='https://open.spotify.com/artist/3osGnalYe1lsn7kzOMxahw' target="_blank" rel="noreferrer"  className="media-link">
            <img src={ic_spotify} className="media-icon" alt="spotify" />
            <span>spotify</span>
          </a>

          <a href='https://www.deezer.com/ru/artist/156332702' target="_blank" rel="noreferrer"  className="media-link">
            <img src={ic_deezer} className="media-icon" alt="deezer" />
            <span>deezer</span>
          </a>

          <a href='https://soundcloud.com/user-818554859' target="_blank" rel="noreferrer"  className="media-link">
            <img src={ic_soundcloud} className="media-icon" alt="soundcloud" />
            <span>soundcloud</span>
          </a>

          <a href='https://stechanka.bandcamp.com/' target="_blank" rel="noreferrer"  className="media-link">
            <img src={ic_bandcamp} className="media-icon" alt="bandcamp" />
            <span>bandcamp</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
